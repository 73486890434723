import React, { useEffect, useState } from "react"
import { Avatar, Box, Button, Card, CardActionArea, CardContent, CardMedia, Chip, Container, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, Paper, TextField, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt, faMapMarkedAlt, faMapMarker, faPerson, faSearch, faTicket, faTicketAlt } from "@fortawesome/free-solid-svg-icons"
import { GetAllEvents } from "../../../controllers/customer/EventosController";
import { imagesURL } from "../../../apiRoutes/apiBase";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import QUDProgress from "../../../components/progress";
import SignInModal from "../../auth/signInModal";

//import concierto_img from '../../../assets/images/concierto.jpeg';
import concierto_img from '../../../assets/images/portadaeventos.png';
import publicidad_img from '../../../assets/images/publicidadEvento2.png';
import { cortarPalabra } from "../../../controllers/AuxController";
import CountryFlag from "../../../components/CountryFlag";

export default function Events()
{
    const [eventos,setEventos] = useState([]);
    const [showModal,setShowModal] = useState(true);
    const [isLoading,setIsLoading] = useState(false);

    const history = useHistory();

    const redireccionar = (route) => {
        history.push(route)
    }

    useEffect(()=>{
        handleGetEventos();
    },[])

    const handleGetEventos = async () => {
        setIsLoading(true);
        let response = await GetAllEvents();
        if(response.success === true)
        {
            setEventos(response.data.events);
            window.scrollTo(0,0);
        }
        setIsLoading(false);
    }

  

    return(
        <>
            {/**Portada */}
            <Box
                sx={{
                    backgroundImage: `url(${concierto_img})`,
                    backgroundRepeat: 'no-repeat', // Controla la repetición de la imagen
                    backgroundSize: 'cover', // Ajusta la imagen para cubrir todo el contenedor
                    backgroundPosition:'center',
                    height: '75vh', // Ajusta la altura del contenedor
                    width: '100%', // Ajusta el ancho del contenedor
                    mt:-15,
                    display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'
                }}
            >
                <Typography variant='h4' color={'primary.contrastText'} fontWeight={'bold'} >Disfruta</Typography>
                <Typography variant='h4' color={'primary.contrastText'} fontWeight={'bold'} >Cada</Typography>
                <Typography variant='h4' color={'primary.contrastText'} fontWeight={'bold'} >Momento</Typography>
                <Typography variant='body1' color={'link.light'} fontWeight={'bold'} mt={1}>#EVENTOS #QUD #QUDLIFE</Typography>
            </Box>
            
            <Container maxWidth='lg'>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={-5}>
                        <Paper elevation={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant='h4' fontWeight={'bold'} textAlign={'center'}>Próximos Eventos</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>


            <Container maxWidth='lg' sx={{mt:10}}>
                <Grid container spacing={3}>
                    {/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant='h4' fontWeight={'bold'}>Próximos Eventos</Typography>
                    </Grid>*/}

                    {
                        eventos.length !== 0 &&
                        eventos.map((item) =>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                <Card sx={{p:0}} elevation={2}>
                                    <CardActionArea onClick={()=>redireccionar('/eventos/'+item.id)}>
                                        
                                        <CardMedia
                                            component="img"
                                            image={imagesURL+'eventos/'+item.imagen}
                                            alt={`evento ${item.nombre}`}
                                            sx={{width:{sm:332.5,md:282.98,xl:270},height:{sm:332.5,md:282.98,xl:270},objectFit:'cover',overflowY:'hidden'}}
                                        />
                                        <CardContent>
                                            
                                            <Typography component={'h3'} variant="body1" fontWeight={'bold'} >
                                                {cortarPalabra(`${item.nombre}`,31)}
                                            </Typography>

                                            <Typography variant="body2" mt={3}>
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                                &nbsp;{item.fecha}
                                            </Typography>
                                            <Typography variant="body2">
                                                <FontAwesomeIcon icon={faMapMarker}/>
                                                &nbsp;{item.ciudad}
                                                &nbsp;&nbsp;<CountryFlag country={item.pais_codigo}/>
                                            </Typography>
                                            <Typography variant="body2">
                                                <FontAwesomeIcon icon={faPerson}/>
                                                &nbsp;{cortarPalabra(`${item.merchant}`,31)}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )
                    }
                </Grid>
            </Container>


            <Container maxWidth='lg' sx={{mt:10,mb:10}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Paper elevation={4} sx={{padding:0}}
                             
                        >
                            <Box
                            sx={{
                                backgroundImage: `url(${publicidad_img})`,
                                backgroundRepeat: 'no-repeat', // Controla la repetición de la imagen
                                backgroundSize: 'cover', // Ajusta la imagen para cubrir todo el contenedor
                                backgroundPosition:'center',
                                height: '100%', // Ajusta la altura del contenedor
                                width: '100%', // Ajusta el ancho del contenedor
                                borderRadius:2
                            }}
                            >
                            <Grid container spacing={3} justifyContent={'center'} alignItems={'center'} p={2}>
                                <Grid item lg={8} xl={8}>
                                    <Typography variant="h3" color={'primary.contrastText'} textAlign={'center'}>
                                        ¡Crea y organiza <br/> tus eventos <br/> de manera sencilla!
                                    </Typography>
                                    <Typography variant="h6" fontWeight={'bold'} color={'link.main'} textAlign={'center'} mt={5}>
                                        Planifica, promociona y gestiona tus eventos desde cualquier lugar.
                                    </Typography>
                                </Grid>
                            </Grid>
                            </Box>
                            
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

        </>
    )
}

/**
 * <Box mt={-3} pb={10}>
            
            <QUDProgress open={isLoading}/>
            <Box sx={{width:'100%',height:300,display:'flex',flexDirection:'column',bgcolor:'primary.main',justifyContent:'center',alignItems:'center'}}>
                <Typography variant='h6' color={'primary.contrastText'} fontWeight={'bold'} >Disfruta</Typography>
                <Typography variant='h6' color={'primary.contrastText'} fontWeight={'bold'} >Cada</Typography>
                <Typography variant='h6' color={'primary.contrastText'} fontWeight={'bold'} >Momento</Typography>
                <Typography variant='body2' color={'primary.contrastText'} fontWeight={'bold'} mt={1}>#EVENTOS #QUD #QUDLIFE</Typography>
            </Box>
            <Container maxWidth='xl'>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} sm={10} md={8} lg={6} xl={6} display={'flex'} justifyContent={'center'} sx={{borderRadius:20}}>
                        <TextField
                            sx={{bgcolor:'#FFF',mt:-3,borderRadius:20,'& fieldset':{borderRadius:20}}}
                            fullWidth
                            placeholder="Buscar"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} mt={5} display={'flex'} justifyContent={'center'}>
                    {
                        eventos.length !== 0 &&
                        eventos.map((item) =>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
                                
                            >
                                <Box sx={{'&:hover':{cursor:'pointer'}}}
                                    onClick={()=>redireccionar('/eventos/'+item.id)}
                                >
                                    <Box sx={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                        <img
                                            style={{
                                                width:335,
                                                height:335,
                                                objectFit:'cover',
                                                objectPosition:'bottom',
                                                borderRadius:20
                                            }}
                                            src={imagesURL+'eventos/'+item.imagen}
                                            alt='QUD'
                                        />
                                    </Box>
                                    <Paper elevation={4} sx={{mt:2,width:335,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                        <Typography variant="h6" color={'primary.main'}>{item.nombre}</Typography>
                                        <Typography variant="body2" color={'primary.main'}>{item.fecha}</Typography>
                                    </Paper>
                                </Box>
                            </Grid>
                        )
                    }
                </Grid>
            </Container>
        </Box>
 */