import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { GetEventByID } from "../../../controllers/customer/EventosController";
import { Avatar, Box, Button, Container, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import { domain, imagesURL } from "../../../apiRoutes/apiBase";
import QUDProgress from "../../../components/progress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDirections, faLink, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faFacebookF, faTelegram, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import moment from "moment";

import ShareButton from "../../../components/ShareButton";
import { FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton, XIcon } from "react-share";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import ReactPlayer from "react-player";
import '../../../App.css'
import ResponseComponent from "../../../components/responseComponent";
import { formatStringWithHyphens } from "../../../controllers/AuxController";

export default function EventsDetails()
{
    const [event,setEvent] = useState([]);
    const [tickets,setTickets] = useState([]);

    const [show,setShow] = useState(false);
    const [success,setSuccess] = useState(false);
    const [message,setMessage] = useState('');
    //const [eventURL,setEventURL] = useState('');

    const [isLoadingData,setIsLoadingData] = useState(false);

    const { id } = useParams();

    const history = useHistory();

    const redireccionar = (route) => {
        history.push(route)
    }

    useEffect(()=>{
        handleGetEvent();
        window.scrollTo(0,0);
    },[])

    const handleGetEvent = async () => {
        setIsLoadingData(true);
        let response = await GetEventByID(id);
        console.log(response.data.tickets)
        if(response.success === true)
        {
            setEvent(response.data.event);
            setTickets(response.data.tickets);
        }
        setIsLoadingData(false);
        window.scrollTo(0,0);
    }

    const imageEvent = event.length !== 0 && `${imagesURL}eventos/${encodeURIComponent(event.imagen)}`;
    const image = encodeURIComponent();

    const eventURL = event.length !== 0 && `${domain}eventos/${event.id}?event=${formatStringWithHyphens(event.nombre)}`;

    const handleCopy = (text) => {
        const url = `${domain}eventos/${event.id}?event=${formatStringWithHyphens(event.nombre)}`;
        navigator.clipboard.writeText(url);
        
        setSuccess(true);
        setMessage('Enlace copiado exitosamente')
        setShow(true);
    }

    return(
        <>
            <QUDProgress open={isLoadingData}/>
            <ResponseComponent
                show={show}
                success={success}
                message={message}
                actionText='Finalizar'
                onClick={()=>setShow(false)}
            />
            {/**Portada */}
            {
                event.length !== 0 &&
                <Box
                    sx={{
                        backgroundImage: `url(${imagesURL}eventos/${encodeURIComponent(event.imagen)})`,
                        //backgroundRepeat: 'no-repeat', // Controla la repetición de la imagen
                        //backgroundSize: 'cover', // Ajusta la imagen para cubrir todo el contenedor
                        backgroundPosition:'top',
                        height: {lg:'70vh',xl:'55vh'}, // Ajusta la altura del contenedor
                        width: '100%', // Ajusta el ancho del contenedor
                        mt:-15,
                        display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',
                    }}
                >
                    <Box
                        sx={{
                            width:'100%',height:'100%',bgcolor:'#00000060',backdropFilter:'blur(20px)',
                            display:'flex',flexDirection:'column',justifyContent:'flex-end'
                        }}
                    >
                        <Container sx={{mb:-5,color:'#FFF'}}>
                            <Grid container spacing={3} mt={3} alignItems={'center'}>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Box sx={{width:'100%'}}>
                                        <img
                                            style={{width:'100%',borderRadius:20}}
                                            src={imagesURL+'eventos/'+event.imagen} 
                                            alt={event.nombre}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Grid container spacing={3} alignItems={'center'}>
                                            <Grid item xl={2}>
                                                <Avatar variant="rounded" sx={{bgcolor:'#FFF',color:'#000',width:80,height:80,display:'flex',flexDirection:'column',borderRadius:2}}>
                                                    <Typography variant='h5' fontWeight={'bold'}>
                                                        {moment(event.fecha).format('MMM')}
                                                    </Typography>
                                                    <Typography variant='h5' fontWeight={'bold'}>
                                                        {moment(event.fecha).format('D')}
                                                    </Typography>
                                                </Avatar>
                                            </Grid>
                                            <Grid item xl={10}>
                                                <Typography variant="h5">
                                                    {event.ciudad}
                                                </Typography>
                                                <Typography variant="h6">
                                                    <FontAwesomeIcon icon={faLocationDot}/> &nbsp;{event.direccion}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                                        <Typography variant="h4" fontWeight={'bold'}>
                                            {event.nombre}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{bgcolor:'#FFFFFF50',borderRadius:2,mb:{xs:8}}} mt={2} p={2}>
                                        <Grid container spacing={3} alignItems={'center'}>
                                            <Grid item xs={12} lg={6} xl={6}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={3} sm={2} md={2} lg={3} xl={3}>
                                                    <Typography fontWeight={'bold'}>Compartir</Typography>
                                                </Grid>
                                                <Grid item xs={9} sm={10} md={10} lg={9} xl={9} display={'flex'} flexDirection={'row'}>
                                                    <FacebookShareButton url={eventURL}>
                                                        <FontAwesomeIcon size="lg" icon={faFacebookF}/>
                                                    </FacebookShareButton>

                                                    <TwitterShareButton url={eventURL} style={{marginLeft:15}}>
                                                        <FontAwesomeIcon size="lg" icon={faTwitter}/>
                                                    </TwitterShareButton>

                                                    <WhatsappShareButton url={eventURL} style={{marginLeft:15}}>
                                                        <FontAwesomeIcon size="lg" icon={faWhatsapp}/>
                                                    </WhatsappShareButton>

                                                    <TelegramShareButton url={eventURL} style={{marginLeft:15}}>
                                                        <FontAwesomeIcon size="lg" icon={faTelegram}/>
                                                    </TelegramShareButton>

                                                    <IconButton style={{marginLeft:10}}  onClick={()=> handleCopy('profile')}>
                                                        <FontAwesomeIcon size="xs" color="#FFF" icon={faLink}/>
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                            </Grid>
                                            <Grid item xs={12} lg={6} xl={6}  justifyContent={'flex-end'}>
                                                <Button fullWidth variant="contained" color="link" onClick={()=>redireccionar('/eventos/'+event.id+'/tickets')}>
                                                    <Typography variant="h6">{event.id === 30 ?'Obtener':'Comprar'} tickets</Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/*<Grid xs={12} sm={12} md={12} lg={12} xl={12} mt={2} mb={2}>
                                        <Grid container spacing={3}>
                                            <Grid item xl={2}>
                                                <Typography>Compartir</Typography>
                                            </Grid>
                                            <Grid item xl={9} display={'flex'} flexDirection={'row'}>
                                                <FacebookShareButton url={eventURL}>
                                                    <FontAwesomeIcon size="lg" icon={faFacebookF}/>
                                                </FacebookShareButton>

                                                <TwitterShareButton url={eventURL} style={{marginLeft:15}}>
                                                    <FontAwesomeIcon size="lg" icon={faTwitter}/>
                                                </TwitterShareButton>

                                                <WhatsappShareButton url={eventURL} style={{marginLeft:15}}>
                                                    <FontAwesomeIcon size="lg" icon={faWhatsapp}/>
                                                </WhatsappShareButton>

                                                <TelegramShareButton url={eventURL} style={{marginLeft:15}}>
                                                    <FontAwesomeIcon size="lg" icon={faTelegram}/>
                                                </TelegramShareButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>*/}
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Box>
            }


            {/**Detalles del evento */}
            <Container sx={{mt:10}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6">
                            Detalles
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                        <Typography variant="h6" fontWeight={'bold'}>Fecha</Typography>
                        <Typography variant="h6">{event.fecha}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                        <Typography variant="h6" fontWeight={'bold'}>Apertura</Typography>
                        <Typography variant="h6">{event.apertura}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                        <Typography variant="h6" fontWeight={'bold'}>Inicio</Typography>
                        <Typography variant="h6">{event.hora}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                        <Typography variant="h6" fontWeight={'bold'}>Edad</Typography>
                        <Typography variant="h6">{event.edad}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                        <Typography variant="h6" fontWeight={'bold'}>Asientos</Typography>
                        <Typography variant="h6">{event.asientos}</Typography>
                    </Grid>

                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
                        <Divider/>
                    </Grid>
                </Grid>
            </Container>

            {/**Descripcion del evento */}
            <Container sx={{mt:5}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6">
                            Descripción
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={7} xl={7} sx={{ fontSize: '16px !important' }}>
                        {
                            event.length !== 0 &&
                            <ReactQuill 
                                className="my-quill-container"
                                //style={{fontSize:'20px!important'}}
                                
                                theme={'bubble'} 
                                readOnly={true} 
                                modules={{toolbar:false}} 
                                value={event.descripcion}
                            />
                        }
                    </Grid>
                    {
                        (event.length !== 0 && event.youtube) &&
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                            <Box sx={{width:'100%'}}>
                                <ReactPlayer width={'100%'} url={event.youtube} />
                            </Box>
                        </Grid>
                    }

                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
                        <Divider/>
                    </Grid>
                </Grid>
            </Container>

            {/**Direccion del evento */}
            <Container sx={{mt:5}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6">
                            Dirección
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                        <Typography variant="h6">{event.direccion}</Typography>
                        <Button
                            startIcon={<FontAwesomeIcon icon={faDirections}/>}
                            target="_blank"
                            color="link"
                            href={'http://maps.google.com/maps?daddr='+parseFloat(event.latitud)+','+parseFloat(event.longitud)}
                        >
                            <Typography>Cómo llegar</Typography>
                        </Button>
                    </Grid>

                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
                        <Divider/>
                    </Grid>
                </Grid>
            </Container>


            {/**Direccion del evento */}
            <Container sx={{mt:5}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6">
                            ¿Tienes preguntas sobre el evento?
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Typography variant="h6" fontWeight={'bold'}>
                            Organizador
                        </Typography>
                        <Button
                            color="primary"
                            onClick={()=>redireccionar(`/merchants/${event.merchant_uuid}/profile`)}
                        >
                            <Typography variant="h6">{event.merchant}</Typography>
                        </Button>   
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Typography variant="h6" fontWeight={'bold'}>
                            Telefono
                        </Typography>
                        <Button
                            href={`tel:${event.merchant_telefono}`}
                            target="_blank"
                            color="primary"
                        >
                            <Typography variant="h6">{event.merchant_telefono}</Typography>
                        </Button>   
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Typography variant="h6" fontWeight={'bold'}>
                            Whatsapp
                        </Typography>
                        <Button
                            href={`https://wa.me/${event.merchant_telefono}`}
                            target="_blank"
                        >
                            <Typography variant="h6">
                                {event.merchant_telefono}
                            </Typography>
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} alignItems={'center'}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="link"
                            onClick={()=>redireccionar(`/merchants/${event.merchant_uuid}/profile`)}
                        >
                            <Typography variant="h6">
                                Visitar perfil de {event.merchant}
                            </Typography>
                        </Button>
                    </Grid>

                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
                        <Divider/>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}