import React, { useEffect } from 'react';
import { Box } from '@mui/material';



import { useHistory } from 'react-router-dom';


import { useTheme } from '@mui/material';



import "./homeStyles.css";
import Categories from './Categories';



import Carousel from 'react-elastic-carousel'
import styled from "styled-components";


const StyledCarousel = styled(Carousel)`
    
    .rec-dot_active{
        background-color: #bfebff;
        box-shadow: 0 0 1px 3px rgba(20, 122, 194,1);
    }

    .rec-arrow{
        background-color: #e3f6ff
    }

    .rec-arrow:hover {
        background-color: #147ac2
    }

    .rec-arrow:focus{
        background-color: #147ac2
    }
`;


export default function Home(props)
{
    const history = useHistory();
    const theme = useTheme();

    useEffect(()=>{
        redirect('/eventos')
    },[])

    const redirect = (route) => {
        history.push(route);
    }

    const settings = {
        itemsToShow:1,
        //dots: false,
        infinite: true,
        enableAutoPlay: true,
        autoPlaySpeed: 5000,
        //speed:300,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        showArrows: false,
        enableSwipe: true
    };



    return(
        <Box sx={{overflowX:'hidden'}}>
            {/**Carrusel publicitario *}
            <Container maxWidth='lg' sx={{mb:5}}>
                <Box sx={{mb:5}}>
                    <StyledCarousel
                        {...settings}
                        dotsColor='#000'
                    >
                        <Box
                            sx={{
                                bgcolor:'primary.main',
                                color:'primary.contrastText',
                                borderRadius:5,
                                p:5
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}>
                                            <Typography variant='h3' fontWeight={'bold'} gutterBottom>
                                                ¡Empieza hoy mismo a explorar <br/> las posibilidades que te ofrece <br/> nuestra plataforma!
                                            </Typography>
                                            <Typography variant='h6' color={'#CCC'} gutterBottom>
                                                Regístrate de forma gratuita y descubre <br/> un mundo de oportunidades para tu vida.
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'}}}>
                                            <Typography variant='h6' fontSize={19} fontWeight={'bold'} gutterBottom>
                                                ¡Empieza hoy mismo a explorar las posibilidades que te ofrece nuestra plataforma!
                                            </Typography>
                                            <Typography variant='body1' color={'#CCC'} gutterBottom mt={2}>
                                                Regístrate de forma gratuita y descubre un mundo de oportunidades para tu vida.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Button
                                                color='link'
                                                variant='contained'
                                                fullWidth
                                                onClick={()=>redirect('/auth/userType')}
                                            >
                                                <Typography variant='h6' textAlign={'center'}>Registrate ahora</Typography>
                                            </Button>
                                            
                                        </Grid>
                                        <Grid item lg={6}></Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Box sx={{width:'100%'}}>
                                        <img style={{width:'100%',borderRadius:10}} src={t6} alt='QUD'/>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        
                        <Box
                            sx={{
                                bgcolor:'#FBE5E2',
                                color:'primary.main',
                                borderRadius:5,
                                p:5
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}>
                                            <Typography variant='h3' fontWeight={'bold'} color={'#2d2c48'} textTransform={'uppercase'} gutterBottom>
                                                Servicio de traslado de <br/>
                                                <span style={{color:'#E73090'}}>mujeres</span> para <span style={{color:'#E73090'}}>mujeres</span>
                                            </Typography>
                                            <Typography variant='h6' color={'#2d2c48'} gutterBottom>
                                                Muévete por la ciudad disfrutando de un <br/>servicio de traslado seguro y confiable
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'}}}>
                                            <Typography variant='h6' fontWeight={'bold'} color={'#2d2c48'} textTransform={'uppercase'} gutterBottom>
                                                Servicio de traslado
                                                <br/>de <span style={{color:'#E73090'}}>mujeres</span>
                                                <br/>para <span style={{color:'#E73090'}}>mujeres</span>
                                            </Typography>
                                            <Typography variant='body1' color={'#2d2c48'} gutterBottom>
                                                Muévete por la cidudad disfrutando de un servicio<br/> de traslado seguro y confiable
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Button
                                                color='link'
                                                variant='contained'
                                                fullWidth
                                                onClick={()=>redirect('artemis')}
                                                sx={{bgcolor:'#E73090'}}
                                            >
                                                <Typography variant='h6' textAlign={'center'}>Solicitar servicio</Typography>
                                            </Button>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Box sx={{width:'100%',position:'relative',bottom:-45}}>
                                        <img style={{width:'100%',borderRadius:10}} src={artemis_girl} alt='artemis'/>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </StyledCarousel>
                </Box>
            </Container>
            
           
            {/**-------------------CATEGORIAS------------------- */}
            <Categories/>
            <br/>
        </Box>
    )
}


